<template>
  <div class="flip-card col-12 col-sm-6 col-md-4 col-lg-3">
    <div class="flip-card-inner">
      <div class="flip-card-front">
        <img :src="imgSrc" :onerror="`this.src='${defaultImg}'`" alt="Image" />
        <div class="flip-card-title">
          <h4 class="cut-1line" :title="isDataExist(user.userNameCurrent)">
            {{ isDataExist(user.userNameCurrent) }}
          </h4>
        </div>
      </div>
      <div class="flip-card-back">
        <div class="flip-card-title-top">
          <h6 class="cut-1line">{{ isDataExist(user.fullCode) }}</h6>
        </div>
        <div class="flip-card-back-icons">
          <button
            v-b-modal.UserInfo
            class="btn p-0"
            :title="$t('info')"
            @click="setUserData"
          >
            <img src="@/assets/images/info.svg" class="icon-lg" />
          </button>
          <router-link
            v-if="privilegeOfUserEdit()"
            :to="{
              name: PageOfUserEdit,
              params: {
                userToken: user.userToken,
              },
            }"
            :title="$t('edit')"
          >
            <img src="@/assets/images/pencil.svg" class="icon-lg" />
          </router-link>
          <button
            v-if="privilegeOfUserDelete()"
            v-b-modal.UserDelete
            class="btn p-0"
            :title="$t('delete')"
            @click="setUserData"
          >
            <img src="@/assets/images/trash.svg" class="icon-lg" />
          </button>
          <button
            v-b-modal.ActionsData
            class="btn p-0"
            :title="$t('actionsData.modelName')"
            @click="setUserData()"
          >
            <img src="@/assets/images/actions-data.svg" class="icon-lg" />
          </button>
        </div>
        <div class="flip-card-title">
          <h4 class="cut-1line" :title="isDataExist(user.userNameCurrent)">
            {{ isDataExist(user.userNameCurrent) }}
          </h4>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { BASE_URL } from "./../../utils/constants";
import { isDataExist } from "./../../utils/functions";
import { mapGetters } from "vuex";
import { checkPrivilege } from "@/utils/functions";

export default {
  data() {
    return {
      language: localStorage.getItem("userLanguage") || "ar",
      PageOfUserEdit: "",
    };
  },
  props: ["user", "defaultImg"],
  methods: {
    setUserData() {
      this.$emit("setUserData", this.user);
    },
    isDataExist,
    checkPrivilege,
    privilegeOfUserEdit() {
      if (this.PageOfUserEdit === "MasterAdminEdit") {
        return this.checkPrivilege(
          this.usersPrivilegeData.usersPrivilegeMasterAdmins.update
        );
      } else if (this.PageOfUserEdit === "EmployeeEdit") {
        return this.checkPrivilege(
          this.usersPrivilegeData.usersPrivilegeEmployees.update
        );
      } else if (this.PageOfUserEdit === "ClientEdit") {
        return this.checkPrivilege(
          this.usersPrivilegeData.usersPrivilegeClients.update
        );
      } else {
        return this.checkPrivilege(
          this.usersPrivilegeData.usersPrivilegeSeller.update
        );
      }
    },
    privilegeOfUserDelete() {
      if (this.PageOfUserEdit === "MasterAdminEdit") {
        return this.checkPrivilege(
          this.usersPrivilegeData.usersPrivilegeMasterAdmins.archive
        );
      } else if (this.PageOfUserEdit === "EmployeeEdit") {
        return this.checkPrivilege(
          this.usersPrivilegeData.usersPrivilegeEmployees.archive
        );
      } else if (this.PageOfUserEdit === "ClientEdit") {
        return this.checkPrivilege(
          this.usersPrivilegeData.usersPrivilegeClients.archive
        );
      } else {
        return this.checkPrivilege(
          this.usersPrivilegeData.usersPrivilegeSeller.archive
        );
      }
    },
  },
  computed: {
    ...mapGetters(["usersPrivilegeData"]),
    imgSrc: function () {
      return BASE_URL + this.user.userImagePath;
    },
  },
  async created() {
    this.PageOfUserEdit = this.$route.meta.pageEdit || "";
  },
};
</script>

<style lang="scss"></style>
