import PaginationData from "@/models/general/PaginationData";
import User from "./User";
import UsersFilter from "./UsersFilter";

export default class Users {
  constructor() {
    this.setInitialValue();
  }
  setInitialValue() {
    this.status = 0;
    this.msg = "";
    this.pagination = new PaginationData();
    this.usersData = [];
    this.user = new User();
    this.filterData = new UsersFilter();
  }
  fillData(data) {
    this.status = data.status;
    this.msg = data.msg;
    this.pagination.fillData(data.pagination);
    this.usersData = data.usersData;
  }
}
