<template>
  <b-modal id="UserInfo" scrollable size="lg" centered hide-footer>
    <template #modal-title>
      <h3>
        <img src="@/assets/images/users.svg" class="icon-lg" />
        {{ $t("users.data") }}
      </h3>
    </template>
    <div class="">
      <div class="my-card">
        <div class="row">
          <DataLabelGroup
            :className="'col-lg-6'"
            :value="user.userNameAr"
            :title="$t('users.nameAr')"
            :imgName="'users.svg'"
          />
          <DataLabelGroup
            :className="'col-lg-6'"
            :value="user.userNameEn"
            :title="$t('users.nameEn')"
            :imgName="'users.svg'"
          />

          <DataLabelGroup
            :className="'col-lg-6 phone-number'"
            :value="user.userPhoneWithCC"
            :title="$t('users.phone')"
            :imgName="'phone.svg'"
          />
          <DataLabelGroup
            :className="'col-lg-6'"
            :value="user.userEmail"
            :title="$t('users.email')"
            :imgName="'email.svg'"
          />

          <DataLabelGroup
            :className="'col-lg-6'"
            :value="user.userAddressAr"
            :title="$t('users.addressAr')"
            :imgName="'address.svg'"
          />
          <DataLabelGroup
            :className="'col-lg-6'"
            :value="user.userAddressEn"
            :title="$t('users.addressEn')"
            :imgName="'address.svg'"
          />

          <DataLabelGroup
            :className="'col-lg-6'"
            :value="user.userNationalID"
            :title="$t('users.nationalID')"
            :imgName="'nationalNumber.svg'"
          />
          <DataLabelGroup
            :className="'col-lg-6'"
            :value="user.userExpiryDateNationalID"
            :title="$t('users.expiryDateTimeNationalID')"
            :imgName="'date.svg'"
          />

          <DataLabelGroup
            :className="'col-lg-6'"
            :value="user.userGenderCurrentName"
            :title="$t('users.gender')"
            :imgName="'gender.svg'"
          />
          <DataLabelGroup
            :className="'col-lg-6'"
            :value="user.establishmentRoleData.establishmentRoleNameCurrent"
            :title="$t('users.role')"
            :imgName="'establishment-role.svg'"
          />

          <DataLabelGroup
            :className="'col-lg-6'"
            :value="user.fullCode"
            :title="$t('users.code')"
            :imgName="'code.svg'"
          />
          <DataLabelGroup
            :className="'col-lg-6'"
            :value="user.userActiveStatus ? $t('yes') : $t('no')"
            :title="$t('users.activeStatus')"
            :imgName="'type.svg'"
          />
        </div>
      </div>
    </div>
  </b-modal>
</template>

<script>
import DataLabelGroup from "@/components/general/DataLabelGroup.vue";

export default {
  components: {
    DataLabelGroup,
  },
  data() {
    return {
      language: localStorage.getItem("userLanguage") || "ar",
    };
  },
  props: ["user"],
  methods: {},
};
</script>

<style scoped lang="scss"></style>
